import * as Configure from '../configure/urlConfig';
import * as Action from './actions';
import fetchJsonp from 'fetch-jsonp'
export function httpGet(jsonp, local, path) {
    return callApi(jsonp, local, path, 'GET', null, false);
}
export function httpPost(jsonp, local, path, body) {
    return callApi(jsonp, local, path, 'POST', body, false);
}
export function httpPut(jsonp, local, path, body) {
    return callApi(jsonp, local, path, 'PUT', body, false);
}
export function httpDelete(jsonp, local, path) {
    return callApi(jsonp, local, path, 'DELETE', null, false);
}
export function httpPostFormData(jsonp, local, path, body) {
    return callApi(jsonp, local, path, 'POST', body, false, true);
}
export function httpPostWithFile(jsonp, local, path, body) {
    return callApi(jsonp, local, path, 'POST', body, false, true);
}
export function httpPutFormData(jsonp, local, path, body) {
    return callApi(jsonp, local, path, 'PUT', body, false);
}
function callApi(jsonp, local, path, method, body = null, https = false, isFormData = false) {
    let url = '';
    if (local === true) {
        url = Configure.BASE_URL_WORLDWFONE_LOCAL + path;
    }
    else {
        url = Configure.BASE_URL_WORLDWFONE + path;
    }
    const requestHeaders = new Headers();
    // requestHeaders.set('Content-Type', 'application/json');
    let requestInfo;
    switch (method) {
        case 'POST':
        case 'PUT':
            if (isFormData === false) {
                requestInfo = { method: method, headers: requestHeaders, body: JSON.stringify(body) };
            } else {
                requestHeaders.delete('Content-Type');
                requestInfo = { method: method, headers: requestHeaders, body: body };
            }
            break;
        case 'DELETE':
        case 'GET':
            requestInfo = { method: method, headers: requestHeaders };
            break;
        default:
            break;
    }

    if (jsonp === true) {
        requestInfo.jsonpCallbackFunction = 'wfappcallback'
        return new Promise((resolve, reject) => {
            fetchJsonp(url, requestInfo)
                .then(function (response) {
                    return response.json()
                }).then(function (json) {
                    resolve(json)
                }).catch(function (ex) {
                    reject(ex)
                })
        });
    } else {
        return new Promise((resolve, reject) => {
            fetch(url, requestInfo)
                .then(response => {
                    return resolve(response.json());
                })
                .catch(ex => {
                    return reject(ex);
                })
        });

    }
}
