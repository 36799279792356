import * as ApiCaller from '../libs/worldfoneRequests';
import * as ApiCallerMTC from '../libs/httpRequests';
import { store } from '../stores';

export function makecall(phone, name, token) {
    if (!name || name === '')
        name = "1";
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(true, true, `makecall?phone=${phone}&name=${name}&token=${token}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function hangup(token) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(true, true, `hangup?token=${token}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function logout(token) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(true, true, `logout?token=${token}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function answer(token) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(true, true, `answer?token=${token}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function getcdr2(calluuid, secrect) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(false, false, `externalcrm/getcdr2.php?calluuid=${calluuid}&secrect=${secrect}&version=4`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetToken(data, username) {
    return new Promise((resolve, reject) => {
        return ApiCaller.httpPostFormData(false, false, `externalcrm/softphonetoken/get.php`, data).then(response => {
            if (response.status === true) {
                let userWF = username;
                let tokenWF = response.data.token;
                localStorage.setItem('userWF', userWF);
                localStorage.setItem('tokenWF', tokenWF);
            }
            return resolve(response)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function login(username, token) {
    // let user = userextension.split('@')[0];
    return new Promise((resolve, reject) => {
        return ApiCaller.httpGet(true, true, `login?username=${username}&token=${token}`).then(response => {
            return resolve(response)
        }).catch(err => {
            return reject(err)
        })
    });
};
const prefix = "Worldfone";
export function UpdateWorldfone(data) {
    if (data) {
        data.billduration = data.billduration?.toString();
        data.totalduration = data.totalduration?.toString();
    }
    return new Promise((resolve, reject) => {
        return ApiCallerMTC.httpPost(`${prefix}/UpdateWorldfone`, data).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};

export function GetListCalls(key, page, size, dateFrom, dateTo, status) {
    return new Promise((resolve, reject) => {
        return ApiCallerMTC.httpGet(`${prefix}/GetListCalls?dateFrom=${dateFrom}&dateTo=${dateTo}&key=${key}&page=${page}&size=${size}&status=${status}`).then(respone => {
            return resolve(respone)
        }).catch(err => {
            return reject(err)
        })
    });
};
