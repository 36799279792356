import { Button, DatePicker, Form, InputNumber, Modal, Select, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import * as Actions from '../../../../libs/actions';
import * as commonService from '../../../../services/commonService';
import * as configService from '../../../../services/configService';
import * as estimatesService from '../../../../services/estimatesService';
import * as InHospitalService from '../../../../services/inPatientService';
import * as MedicineService from '../../../../services/medicineService';
import * as NhapkhoaService from '../../../../services/nhapkhoaService';
import Create from './create';
import Detail from './detail';

const { Option } = Select;
const { RangePicker } = DatePicker;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstPatient: [],
            ngay: [moment(), moment()],
            nhom: '',
            lstNhom: [],
            khoa: '',
            lstKhoa: [],
            phieu: '',
            lstPhieu: [],
            kho: '',
            lstKho: [],
            lstNguon: [],
            nguon: '',
            key: '',
            detail: {},
            lstBN: [],
            ngaylap: moment(),
            thang: moment().month(),
            nam: moment().year(),
            lstDuTru: [],
            lstPhieuDaNhap: [],
            visibleDetail: false,
        };
    }
    componentDidMount() {
        this.loadKhoaCanNhap();
        this.DanhSachKho();
        this.DanhSachLoaiVP();
        this.DanhSachNguon();
    }
    closeModal() {
        this.setState({ visible: false })
    }
    DanhSachLoaiVP() {
        commonService.DanhSachNhomVP().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNhom: result.data
                });
                if (result.data.length > 0) {
                    this.setState({ nhom: result.data[0].value }, () => {
                        this.DanhSachPhieu();
                    })
                } else {
                    this.setState({ lstPhieu: [], lstNhom: [], phieu: '', nhom: '' })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu nhóm bị lỗi")
        })
    }
    loadKhoaCanNhap() {
        NhapkhoaService.DanhSachKhoa().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKhoa: result.data
                });
                if (result.data.length > 0) {
                    this.setState({ khoa: result.data[0].value }, () => { this.DanhSachNhapKhoa() })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu khoa bị lỗi")
        })
    }
    DanhSachNhapKhoa() {
        InHospitalService.DanhSachNoiTru(this.state.department, this.state.key, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPatient: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bệnh nhân nội trú bị lỗi")
        })
    }
    DanhSachPhieu() {
        estimatesService.DanhSachPhieu(this.state.nhom).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhieu: result.data
                });
                if (result.data.length > 0) {
                    this.setState({ phieu: result.data[0].value })
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu phiếu bị lỗi")
        })
    }
    DanhSachPhieuDaNhap() {
        estimatesService.DanhSachPhieuDaNhap(this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstPhieuDaNhap: result.data
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu phiếu bị lỗi")
        })
    }
    DanhSachKho() {
        configService.danhsachkhoconfig().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstKho: result.data
                });
            }
            if (result.data.length > 0) {
                this.setState({ kho: result.data[0].value })
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu Kho bị lỗi")
        })
    }

    DanhSachNguon() {
        MedicineService.GetListNguon().then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstNguon: result.data,
                    nguon: result.data[0].value
                });
            }
        }).catch(err => {
            //notification.warning({ message: 'Tải dữ liệu nguồn bị lỗi' })
        })
    }
    render() {
        const columns = [
            {
                title: 'Mã phiếu',
                dataIndex: 'idPhieu',
                key: 'idPhieu',
            },
            {
                title: 'Tên phiếu',
                dataIndex: 'tenphieu',
                key: 'tenphieu',
            },
            {
                title: 'Ngày',
                dataIndex: 'ngay',
                key: 'ngay',
                render: (value) => {
                    return <span>{moment(value).format('DD/MM/YYYY')}</span>;
                }
            },
            {
                title: 'Khoa',
                dataIndex: 'tenkhoa',
                key: 'tenkhoa'
            },
            {
                title: 'Người lập',
                dataIndex: 'hoten',
                key: 'hoten'
            }
        ];
        return (
            <React.Fragment>
                <div style={{ marginBottom: 20 }}>
                    <div style={{ padding: "5px 20px" }}>
                        <div className='ccCenter'>
                            <h4 style={{ textTransform: "uppercase" }}> Dự trù thuốc</h4>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginBottom: "8px", justifyContent: "center", flexWrap: 'wrap' }}>

                        <Form.Item label="Ngày:">
                            <DatePicker locale={viVN} style={{ width: '100%' }} value={this.state.ngaylap} showTime={true} allowClear={false} format="DD/MM/YYYY HH:mm"
                                onChange={(e) => this.setState({ ngaylap: e })}
                            />
                        </Form.Item>

                        <Form.Item label="Số liệu tháng:">
                            <InputNumber style={{ width: "50px" }} value={this.state.thang} min={1} max={12} onChange={(e) => this.setState({ thang: e })} />
                        </Form.Item>
                        <Form.Item label="Năm:">
                            <InputNumber style={{ width: "70px" }} value={this.state.nam} min={moment().year() - 3} max={moment().year() + 3} onChange={(e) => this.setState({ nam: e })} />
                        </Form.Item>

                        <Form.Item label="Nhóm:">
                            <Select style={{ width: '100%' }} value={this.state.nhom} onChange={(value) => { this.setState({ nhom: value }, () => this.DanhSachPhieu()) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstNhom.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item disabled={true} style={{ flex: 1, display: 'none' }} label="Nguồn:">
                            <Select
                                style={{ width: '100%' }}
                                disabled={true}
                                value={this.state.nguon}
                                showSearch
                                onChange={(value) => { this.setState({ nguon: value }) }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstNguon.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Khoa:">
                            <Select style={{ width: '100%' }} value={this.state.khoa}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstKhoa.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Phiếu:">
                            <Select style={{ width: '100%' }} value={this.state.phieu} onChange={(value) => { this.setState({ phieu: value }) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {/* <Option key={-1} value={''}>Tất cả</Option> */}
                                {this.state.lstPhieu.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Kho:">
                            <Select style={{ width: '100%' }} value={this.state.kho} onChange={(value) => { this.setState({ kho: value }) }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstKho.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.value}>{row.label}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                    </div>
                    <div style={{ marginTop: 10, display: "flex", justifyContent: "center" }}>
                        <Form.Item label="Ngày lập phiếu:">
                            <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                                locale={viVN} onChange={(value => this.setState({ ngay: value }))}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() =>
                                 { this.DanhSachPhieuDaNhap() }}> <i style={{marginRight:10}} className="fa fa-search"/>Tìm</Button>
                        </Form.Item>
                        <Form.Item style={{ marginLeft: '70px !important'  }}>
                            <Button  type="primary" 
                            onClick={() => { this.setState({ visible: true }) }}><i style={{marginRight:10}} className="fa fa-plus"/>Tạo phiếu mới</Button>
                        </Form.Item>
                    </div>
                    <div style={{padding:10}}>
                        <Table dataSource={this.state.lstPhieuDaNhap} columns={columns} rowKey={(row, index) => index} 
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: e => { this.setState({ visibleDetail: true, detail: record })},
                                };
                            }}
                            rowClassName={(record, rowIndex) => {
                                return "rowCustom"
                            }} />
                    </div>
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.visible}
                        onCancel={() => this.setState({ visible: false })}
                        footer={[
                            <Button key="1" type="primary" onClick={() => { this.create.LuuDuTruThuoc() }}> <i style={{marginRight:10}} className="fa fa-save"/>Lưu</Button>,
                        ]}
                    >
                        {this.state.visible ?
                            <Create
                                ref={(c) => this.create = c}
                                khoa={this.state.khoa}
                                nhom={this.state.nhom}
                                makho={this.state.kho}
                                manguon={this.state.nguon}
                                ngay={this.state.ngaylap}
                                phieu={this.state.phieu}
                                closeModal={this.closeModal.bind(this)}
                            />
                            : ""}
                    </Modal>
                    <Modal maskClosable={false}
                        width={1000}
                        visible={this.state.visibleDetail}
                        onCancel={() => this.setState({ visibleDetail: false })}
                        footer={[]}
                    >
                        {this.state.visibleDetail ?
                            <Detail
                                id={this.state.detail.idPhieu} ngay={this.state.detail.ngay}
                            />
                            : ""}
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}
