import { Tabs } from 'antd';
import DanhSachBenhNhan from './quanlybenhnhan'
import DanhSachKhamBenh from './danhsachkhambenh/danhsachketqua'
import React from 'react';
const { TabPane } = Tabs;
export default function QuanLyBenhNhan() {
    return (
        <>
            {/* <Tabs style={{ overflowY: 'auto' }}>
                <TabPane tab={"Danh sách bệnh nhân"} key="1">
              */}
                        <DanhSachBenhNhan />
                

                {/* </TabPane>
                <TabPane tab={"Kết quả khám bệnh"} key="2">
                    <DanhSachKhamBenh />
                </TabPane>

            </Tabs> */}
        </>
    )

}