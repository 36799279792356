import { Checkbox, DatePicker, Form, Input, notification, Select, TimePicker } from 'antd';
import moment from 'moment';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import Autocomplete from '../../../components/autocomplete';
import * as Actions from '../../../libs/actions';
import * as CommonService from '../../../services/commonService';
import * as EnterInfoService from '../../../services/enterInfoService';
import * as NhapkhoaService from '../../../services/nhapkhoaService';
import * as NhapVienService from '../../../services/nhapVienService';
import AutocompleteMulti from '../../../components/autocompleteMulti';
import EnterInfo from '../components/enterInfo';


const { Option } = Select;
const initialState = {
    dateInHospital: moment(),
    timeInHospital: moment(),
    dateInDepartment: moment(),
    timeInDepartment: moment(),
    receiveFrom: '',
    switchFrom: '',
    switchFromName: '',
    lstSwitchFrom: [],
    lstCdFrom: [],
    doctorReceive: '',
    lstDoctorReceive: [],
    enterNumber: '',
    nearPoor: false,
    nearPoorCode: '',
    otherDiseases: '',
    room: '',
    otherDiseasesName: '',
    clinical: '',
    lstClinical: [],
}
export default class editModal extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator(ValidationCustom);
        this.state = {
            ...initialState,
            lstReceiveFrom: [],
            department: '',
            cicd10: '',
            lstCICD10: [],
            visible: false,
            detail: {},
            room: '',
            lstRoom: [],
            typeRoom: '',
            lstTypeRoom: [],
            bed: '',
            lstBed: [],
            lstOtherDiseases: [],
            doctorReceive: '',
            CDkemtheo: [], 
        };
    }
    componentDidMount() {
        this.setData();
        this.loadLoaiPhong();
        this.loadCICD10();
        this.loadNhanTu();
        this.loadDSChuyenDen();
        this.loadDoctor();
        this.loadClinical();

    }
    async getData() {
        return new Promise((resolve, reject) => {
            this.form.validateFields().then(() => {
                let state = this.state;
                let data = {
                    ...this.enterinfo.state, ...state
                }
                resolve(data);
            }).catch(() => {
                resolve(null);
            })
        })
    }
    SetCDkemtheo(item) {
        let lst = [];
        item.forEach(x => {
            lst.push({ value: x.key, label: x.children })
        })
        this.setState({ CDkemtheo: lst })
    }
    setData() {
        NhapVienService.ThongTinBenhNhap(this.props.detail.code, this.props.detail.managerId, this.props.detail.enterCode).then(result => {
            if (result.isSuccess) {
                let lstCD = result.item.lstOtherDiseases.map(x => ({ 'label': x.children, 'value': x.value }))
                this.setState({ lstOtherDiseases: lstCD, otherDiseases: result.item.lstOtherDiseases, CDkemtheo:result.item.lstOtherDiseases });
                this.enterinfo.setState({
                    lstcardAddress: [{ 'value': result.item.cardCode, 'label': result.item.cardName }],
                    route: result.item.route ? result.item.route : false,
                    cardNumber: result.item.cardNumber ? result.item.cardNumber : '',
                    cardCode: result.item.cardCode ? result.item.cardCode : '',
                    cardAddress: result.item.cardCode ? result.item.cardCode : '',
                    cardAddressLabel: result.item.cardName ? result.item.cardName : '',
                    cardLabel: result.item.cardNumber ? true : false,
                    dateExpired: [moment(result.item.cardFrom).year() !== 1 ? moment(result.item.cardFrom) : '', moment(result.item.cardTo).year() !== 1 ? moment(result.item.cardTo) : ''],
                    subject: result.item.subjectCode !== '' ? Number(result.item.subjectCode) : 2,
                });
                this.form.setFieldsValue({
                    subject: result.item.subjectCode !== '' ? Number(result.item.subjectCode) : 2,
                    route: result.item.route ? result.item.route : false,
                    cardNumber: result.item.cardNumber ? result.item.cardNumber : '',
                    cardCode: result.item.cardCode ? result.item.cardCode : '',
                    cardAddress: result.item.cardCode ? result.item.cardCode : '',
                    cardAddressLabel: result.item.cardName ? result.item.cardName : '',
                    cardLabel: result.item.cardNumber ? true : false,
                    dateExpired: [moment(result.item.cardFrom).year() !== 1 ? moment(result.item.cardFrom) : '', moment(result.item.cardTo).year() !== 1 ? moment(result.item.cardTo) : ''],

                })
            }
        })
        const row = this.props.detail
        let lstSwitch = this.state.lstSwitchFrom;
        let lstCD = this.state.lstCdFrom;
        let lstRecommend = this.enterinfo.state.lstRecommend;
        let lstCDFrom = this.enterinfo.state.lstCdFrom;
        const indexSwitch = lstSwitch.findIndex(x => x.value === row.switchFrom);
        const indexCD = lstCD.findIndex(x => x.value === row.diagnostic);
        const indexRecommend = lstRecommend.findIndex(x => x.value === row.switchHospital);
        const indexCDFrom = lstCDFrom.findIndex(x => x.value === row.cdFrom);
        if (indexSwitch < 0) {
            lstSwitch.push({ "value": row.switchFrom, "label": row.switchFromName })
        }
        if (indexCD < 0) {
            lstCD.push({ "value": row.diagnostic, "label": row.diagnosticName })
        }
        if (indexRecommend < 0) {
            lstRecommend.push({ "value": row.switchHospital, "label": row.switchHospitalName })
        }
        if (indexCDFrom < 0) {
            lstCDFrom.push({ "value": row.cdFrom, "label": row.cdFromName })
        }
        this.setState({
            clinical: row.loaiba,
            dateInHospital: moment(row.dateInHospital),
            timeInHospital: moment(row.dateInHospital),
            receiveFrom: row.receiveFrom,
            switchFrom: row.switchFrom,
            lstSwitchFrom: lstSwitch,
            enterNumber: row.enterNumber,
            diagnostic: row.diagnostic,
            lstCdFrom: lstCD,
            doctorReceive: row.doctorReceive,
            diagnosticName: row.diagnosticName,
        });
        this.enterinfo.setState({
            comeFrom: row.comeFrom,
            recommend: row.switchHospital,
            lstRecommend: lstRecommend,
            lstCdFrom: lstCDFrom,
            cdFrom: row.cdFrom,
            cdFromName: row.cdFromName,
            diagnostic: row.diagnostic,
            receiveFrom: row.receiveFrom,
        });
        this.form.setFieldsValue({
            receiveFrom: row.receiveFrom,
            switchFrom: row.switchFrom,
            diagnostic: row.diagnostic,
            doctorReceive: row.doctorReceive,
            recommend: row.switchHospital,
            cdFrom: row.cdFrom,
            comeFrom: row.comeFrom,
        })
    }
    loadLoaiPhong() {
        CommonService.DanhSachLoaiPhong(this.props.makp).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstTypeRoom: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadPhong() {
        CommonService.DanhSachPhong(this.props.makp, this.state.typeRoom).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstRoom: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadGiuong() {
        CommonService.DanhSachGiuong(this.state.room).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBed: result.data,
                });
                if (result.data.length === 0) {
                    Actions.openMessageDialog("Thông báo", "Hiện tại không còn giường trống ở phòng này!")
                }
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", err)
        })
    }
    loadNhanTu() {
        CommonService.GetListNhanTu().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstReceiveFrom: result.data,
                    receiveFrom: result.data[0].value
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu nhận từ bị lỗi' })
        })
    }
    loadClinical(makp) {
        NhapkhoaService.DanhSachBenhAn(makp).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstClinical: result.data,
                    clinical: '2'
                });
                this.form.setFieldsValue({ clinical: '2' })
            }
            else {
                this.setState({
                    clinical: '',
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh án lỗi' })
        })
    }

    loadCICD10() {
        EnterInfoService.GetListCICD10().then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCICD10: result.data,
                    cicd10: result.data[0].value
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu bệnh bị lỗi' })
        })
    }
    loadDiagnostic(value, title) {
        if (title === 'kèm theo') {
            CommonService.GetListDiagnostic(value).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    this.setState({ lstOtherDiseases: result.data });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            })
        }
        else {
            CommonService.GetListDiagnostic(value).then(result => {
                if (result.isSuccess && result.data.length > 0) {
                    this.setState({ lstCdFrom: result.data });
                }
            }).catch(err => {
                Actions.openMessageDialog("Lỗi", err);
            })
        }
    }

    loadDoctor() {
        CommonService.GetListDoctor(1, 200).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstDoctorReceive: result.data,
                });
            }
        }).catch(err => {

            notification.warning({ message: 'Tải dữ liệu bác sĩ bị lỗi' })
        })
    }

    loadDSChuyenDen() {
        CommonService.DSNoiChuyenDen(this.state.department).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstSwitchFrom: result.data
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi chuyển đến bị lỗi' })
        })
    }

    loadNoiChuyen(makhoa, maql) {
        CommonService.NoiChuyenDen(makhoa, maql).then(result => {
            if (result.isSuccess) {
                const id = result.item;
                if (id === '-1') {
                    notification.warning({ message: 'Bệnh nhân này chưa nhập viện !' })
                }
                else if (id === '-2') {
                    notification.warning({ message: 'Bệnh nhân này chưa được chỉ định vào khoa !' })
                }
                else {
                    this.setState({
                        switchFrom: id,
                    });
                    this.form.setFieldsValue({ switchFrom: id })
                }
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu nơi chuyển bị lỗi' })
        })
    }
    setFormEnterInfo(object) {
        this.form.setFieldsValue(object)
    }
    render() {
        return (
            <React.Fragment>
                <Form ref={(c) => this.form = c}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <label>Ngày vào viện</label>
                            <Form.Item>
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <DatePicker value={this.state.dateInHospital} placeholder="YYYY-MM-DD"
                                            className="w-100"
                                            locale={viVN}
                                            format="DD-MM-YYYY"
                                            onChange={(date) => { this.setState({ dateInHospital: date }) }} /></div>
                                    <div className="col-6">
                                        <TimePicker
                                            locale={viVN}
                                            className="w-100"
                                            value={this.state.timeInHospital}
                                            format={'HH:mm'}
                                            onChange={(date) => { this.setState({ timeInHospital: date }) }}
                                        />
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Ngày vào khoa</label>
                            <Form.Item >
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <DatePicker value={this.state.dateInDepartment} placeholder="YYYY-MM-DD"
                                            className="w-100"
                                            locale={viVN}
                                            format="DD-MM-YYYY"
                                            onChange={(date) => { this.setState({ dateInDepartment: date }) }} />
                                    </div>
                                    <div className="col-6">
                                        <TimePicker
                                            value={this.state.dateInDepartment}
                                            className="w-100"
                                            format={'HH:mm'}
                                            onChange={(date) => { this.setState({ dateInDepartment: date }) }}
                                        />
                                    </div>
                                </div>
                            </Form.Item>
                        </div>

                        <div className="col-md-6 mb-2">
                            <label>Nhận từ</label>
                            <Form.Item name="receiveFrom" rules={[{ required: true, message: 'Vui lòng chọn nhận từ!' }]}>
                                <Select value={this.state.receiveFrom} onChange={(value) => { this.setState({ receiveFrom: value }); this.form.setFieldsValue({ receiveFrom: value }) }}>
                                    {this.state.lstReceiveFrom.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Bệnh án</label>
                            <Form.Item name="clinical" rules={[{ required: true, message: 'Vui lòng chọn bệnh án!' }]}>
                                <Select value={this.state.clinical}
                                    onChange={(value) => { this.setState({ clinical: value }); this.form.setFieldsValue({ clinical: value }) }}
                                >
                                    {this.state.lstClinical.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Nơi chuyển</label>
                            <Form.Item name="switchFrom" rules={[{ required: true, message: 'Vui lòng chọn nơi chuyển!' }]}>
                                <Select value={this.state.switchFrom}
                                    onChange={(value, e) => { this.setState({ switchFrom: value, switchFromName: e.children }); this.form.setFieldsValue({ switchFrom: value }) }}>
                                    {this.state.lstSwitchFrom.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Số vào viện</label>
                            <Form.Item >
                                <Input value={this.state.enterNumber} onChange={(e) => { this.setState({ enterNumber: e.target.value }) }} />
                            </Form.Item>
                        </div>
                    </div>
                    <EnterInfo
                        ref={((c) => this.enterinfo = c)}
                        setForm={this.setFormEnterInfo.bind(this)}
                    />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <label>Cận nghèo</label>
                            <Form.Item>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <Checkbox onChange={(e) => { this.setState({ nearPoor: e.target.checked }) }}></Checkbox>
                                        </div>
                                    </div>
                                    <Input disabled={!this.state.nearPoor} value={this.state.nearPoorCode}
                                        style={{ width: 'calc(100% - 50px)' }}
                                        onChange={(e) => {
                                            this.setState({ nearPoorCode: e.target.value });
                                            this.form.setFieldsValue({ nearPoorCode: e.target.value })
                                        }} />
                                </div>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Chẩn đoán</label>
                            <Form.Item name="diagnostic" rules={[{ required: true, message: 'Vui lòng nhập chẩn đoán khoa điều trị!' }]}>
                                <Autocomplete
                                    width='100%'
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstCdFrom}
                                    handleSearch={this.loadDiagnostic.bind(this)}
                                    getItem={(key, value) => { this.setState({ diagnostic: key, diagnosticName: value }); this.form.setFieldsValue({ diagnostic: key }) }}
                                />
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>BS điều trị</label>
                            <Form.Item name="doctorReceive" rules={[{ required: true, message: 'Vui lòng chọn bác sĩ điều trị!' }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => { this.setState({ doctorReceive: value }); this.form.setFieldsValue({ doctorReceive: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstDoctorReceive.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-6 mb-2">
                            <label>Loại phòng</label>
                            <Form.Item name="typeRoom" >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => { this.setState({ typeRoom: value }, () => this.loadPhong()); this.form.setFieldsValue({ typeRoom: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstTypeRoom.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-12 mb-2">
                            <label>Bệnh kèm theo</label>
                            <Form.Item  >
                                <AutocompleteMulti
                                    ref={((c) => this.autocomplete = c)}
                                    dataSource={this.state.lstOtherDiseases}
                                    handleSearch={this.loadDiagnostic.bind(this)}
                                    getItem={(item) => { this.SetCDkemtheo(item) }}
                                    value={this.state.CDkemtheo}
                                    title='kèm theo'
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", marginBottom: "8px" }}>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Phòng:" name="room" rules={[{ required: true, message: 'Vui lòng chọn phòng!' }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => { this.setState({ room: value }, () => this.loadGiuong()); this.form.setFieldsValue({ room: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstRoom.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form.Item label="Giường:" name="bed" rules={[{ required: true, message: 'Vui lòng chọn giường!' }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => { this.setState({ bed: value }); this.form.setFieldsValue({ bed: value }) }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.state.lstBed.map((row, index) => {
                                        return (
                                            <Option key={index} value={row.value}>{row.label}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                    </div> */}
                </Form>
            </React.Fragment>
        )
    }
}
