import { Button, DatePicker, Form, Input, InputNumber, notification, Popconfirm, Select, Table } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import Autocomplete from '../../../../components/autocomplete';
import * as Actions from '../../../../libs/actions';
import * as ChiDinhService from '../../../../services/chiDinhService';
import * as MedicineService from '../../../../services/medicineService';
import * as estimatesService from '../../../../services/estimatesService';
import * as InHospitalService from '../../../../services/inPatientService';
const { RangePicker } = DatePicker;

const { Option } = Select;
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lstThuoc: [],
            thuoc: '',
            soluong: 1,
            cachdung: '',
            songay: 1,
            lstDuTru: [],
            tenthuoc: '',
            lstBN: [],
            ngay: [moment(), moment()],
            mabn: '',
            detail: {},
            room: '',
            ngayvv: '',
            lstThuocDuTru: [],
            lstDisplay: [],
        };
    }
    componentDidMount() {
        this.DanhSachNhapKhoa();
    }
    DanhSachNhapKhoa() {
        InHospitalService.DanhSachNoiTru(this.props.khoa, "", this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD')).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstBN: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("Lỗi", "Tải dữ liệu bệnh nhân nội trú bị lỗi")
        })
    }
    setDetail(mabn) {
        const item = this.state.lstBN.find(x => x.code === mabn)
        this.setState({
            mabn: mabn,
            detail: item,
            room: item.roomName + '/' + item.bedName,
            ngayvv: moment(item.dateInHospital).format('DD/MM/YYYY HH:mm'),
        })
    }
    DanhSachThuoc(key) {
        if (this.state.mabn === '' || this.state.mabn === undefined) {
            Actions.openMessageDialog('Lỗi', 'Chưa chọn bệnh nhân!')
            return;
        }
        MedicineService.DanhSachThuoc(this.state.detail.subjectCode, moment().format('YYYY/MM/DD'), key, this.props.manguon, this.props.makho).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstThuoc: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', 'Tải dữ liệu thuốc bị lỗi')
        })
    }
    XoaDuTruThuoc(row) {
        let data = this.state.lstDuTru;
        let child = data.find(x => x.mabn === row.mabn)
        if (child !== undefined) {
            child.lstThuocDuTru = child.lstThuocDuTru.filter(x => x.mathuoc !== row.mathuoc)
            if (child.lstThuocDuTru.length === 0) {
                data = data.filter(x => x.mabn !== row.mabn)
            }
        }
        let lstDisplay = []
        data.map(x =>
            x.lstThuocDuTru.map(y => (
                lstDisplay.push({
                    'mabn': x.mabn,
                    'mathuoc': y.mathuoc,
                    'tenthuoc': y.tenthuoc,
                    'cachdung': y.cachdung,
                    'soluong': y.soluong,
                    'songay': y.songay
                }))
            )
        )
        this.setState({
            lstDuTru: data.map(d => ({ ...d })),
            lstDisplay: lstDisplay.map(d => ({ ...d })),
        })
    }
    ThemDuTruThuoc() {
        let data = this.state.lstDuTru;
        if (this.state.thuoc === '') {
            Actions.openMessageDialog('Lỗi', 'Chưa chọn thuốc')
            return;
        }
        //check bệnh nhân co trong danh sách hay chưa
        let benhnhan = data.find(x => x.mabn === this.state.mabn);
        if (benhnhan == null) {
            data.unshift({
                'mabn': this.state.mabn,
                'mavaovien': this.state.detail.enterCode,
                'maql': this.state.detail.managerId,
                'songay': this.state.songay,
                'madoituong': Number(this.state.detail.subjectCode),
                'lstThuocDuTru': [{
                    mathuoc: this.state.thuoc,
                    tenthuoc: this.state.tenthuoc,
                    soluong: this.state.soluong,
                    cachdung: this.state.cachdung,
                    solan: 0,
                    songay: this.state.songay,
                }]
            })
        }
        else {
            if (benhnhan.lstThuocDuTru.findIndex(x => x.mathuoc === this.state.thuoc) !== -1) {
                Actions.openMessageDialog('Lỗi', 'Thuốc đã có trong danh sách')
                return;
            }
            benhnhan.lstThuocDuTru.unshift({
                mathuoc: this.state.thuoc,
                tenthuoc: this.state.tenthuoc,
                soluong: this.state.soluong,
                cachdung: this.state.cachdung,
                solan: 0,
                songay: this.state.songay,
            })
        }
        let lstDisplay = []
        data.map(x =>
            x.lstThuocDuTru.map(y => (
                lstDisplay.push({
                    'mabn': x.mabn,
                    'mathuoc': y.mathuoc,
                    'tenthuoc': y.tenthuoc,
                    'cachdung': y.cachdung,
                    'soluong': y.soluong,
                    'songay': y.songay
                }))
            )
        )
        this.setState({
            lstDuTru: data.map(d => ({ ...d })),
            lstDisplay: lstDisplay.map(d => ({ ...d })),
        })
    }
    LuuDuTruThuoc() {
        if (this.state.lstDuTru.length === 0) {
            Actions.openMessageDialog('Lỗi', 'Chưa nhập dữ liệu')
            return;
        }
        Actions.setLoading(true);
        const data = {
            makho: this.props.makho,
            manguon: this.props.manguon.toString(),
            ngay: this.props.ngay.format('YYYY/MM/DD HH:mm'),
            nhom: Number(this.props.nhom),
            phieu: this.props.phieu,
            makp: this.props.khoa,
            loai: "1",
            lstDuTru: this.state.lstDuTru,
        }
        estimatesService.LuuDuTruThuoc(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: 'Thêm thành công' })
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString)
            }
            Actions.setLoading(false);
            this.props.closeModal();
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
            Actions.setLoading(false);
        })
    }
    render() {
        const columnDuTru = [
            {
                title: 'Bệnh nhân',
                dataIndex: 'mabn',
                key: 'mabn',
            },
            {
                title: 'Mã',
                dataIndex: 'mathuoc',
                key: 'mathuoc',
            },
            {
                title: 'Tên',
                dataIndex: 'tenthuoc',
                key: 'tenthuoc',
            },
            {
                title: 'Cách dùng',
                dataIndex: 'cachdung',
                key: 'cachdung',
            },
            {
                title: 'Số lượng',
                dataIndex: 'soluong',
                key: 'soluong',
            },
            {
                title: 'Số ngày',
                dataIndex: 'songay',
                key: 'songay',
            },
            {
                title: '',
                render: (row, index) => {
                    return (
                        <Popconfirm
                            title="Bạn có chắc muốn xóa thuốc này không?"
                            onConfirm={() => { this.XoaDuTruThuoc(row) }}
                            okText="Đồng ý" cancelText="Hủy">
                            <Button type="primary" danger ><i className="fa fa-trash"></i></Button>
                        </Popconfirm>
                    )
                }
            }
        ]
        return (
            <React.Fragment>
                <div style={{ padding: "5px 20px" }}>
                    <div className='ccCenter'>
                        <h4 style={{ textTransform: "uppercase" }}><i style={{marginRight:10}} className="fa fa-plus"/> Tạo phiếu dự trù thuốc</h4>
                    </div>
                </div>
                <div style={{ display: "flex", marginBottom: "8px", justifyContent: "center" }}>
                    <Form.Item label="Ngày vào viện:">
                        <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false}
                            locale={viVN} onChange={(value => this.setState({ ngay: value }))}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={() => { this.DanhSachNhapKhoa() }}>Tải danh sách bệnh nhân</Button>
                    </Form.Item>
                </div>
                <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Mã BN:" >
                            <Select style={{ width: '100%' }}
                                showSearch onChange={(e) => this.setDetail(e)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.lstBN.map((row, index) => {
                                    return (
                                        <Option key={index} value={row.code}>{row.code + '-' + row.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Phòng/Giường:" >
                            <Input disabled value={this.state.room} style={{ width: '100%' }} />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Số ngày:">
                            <InputNumber style={{ width: '100%' }} value={this.state.songay} min={1} onChange={(e) => this.setState({ songay: e })} />
                        </Form.Item>
                    </div>
                </div>

                <div style={{ display: "flex", marginBottom: "8px" }}>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Thuốc:" style={{ width: '100%' }}>
                            <Autocomplete
                                width={170}
                                ref={((c) => this.autocomplete = c)}
                                dataSource={this.state.lstThuoc}
                                handleSearch={this.DanhSachThuoc.bind(this)}
                                getItem={(key, value) => { this.setState({ thuoc: key, tenthuoc: value }) }}
                                value={this.state.thuoc}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Số lượng:" >
                            <InputNumber min={1} sstyle={{ width: '100%' }} onChange={(e) => this.setState({ soluong: e })} value={this.state.soluong} />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item label="Cách dùng:" style={{ width: '100%' }}>
                            <Input value={this.state.cachdung} onChange={(e) => this.setState({ cachdung: e.target.value })} />
                        </Form.Item>
                    </div>

                </div>
                <div style={{ textAlign: "center" }}>
                    <Form.Item >
                        <Button type="primary" onClick={() => { this.ThemDuTruThuoc() }}> <i style={{marginRight:10}} className="fa fa-plus"/>Tạo mới</Button>
                    </Form.Item>
                </div>
                <div style={{ display: "flex"}}>
                    <div style={{ flex: 1, marginTop:"8px", textAlign: "center" }}>
                        <Table  scroll={{ y: 0 }} columns={columnDuTru} dataSource={this.state.lstDisplay}
                            rowKey={(row, index) => index}
                             />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
