import UpdateMaterial from './updateMaterial'
import UpdateMedicine from './updateMedicine'
import React from 'react';
export default function RenderModal(props) {
    return (
        <React.Fragment>
            <UpdateMedicine detail={props.detail} nhom={props.detail.nhom} onClose={() => { props.onClose() }}></UpdateMedicine>
            {/* {props.detail.nhom === 2 ?
                <UpdateMaterial></UpdateMaterial>
                :
                <UpdateMedicine detail={props.detail} nhom={props.detail.nhom} onClose={() => { props.onClose() }}></UpdateMedicine>
            } */}
        </React.Fragment>
    )
}