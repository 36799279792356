import { DatePicker, Form, Input, InputNumber, Modal, notification, Pagination, Popover, Select, Tabs, Popconfirm, Button, Tooltip, Checkbox, Tag } from 'antd';
import viVN from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { ValidationCustom } from '../../../commons/validationCustom';
import ModalCLS from '../../../components/MDCLS';
import * as Actions from '../../../libs/actions';
import * as AdministrativeService from '../../../services/administrativeService';
import * as ChiDinhService from '../../../services/chiDinhService';
import * as CommonService from '../../../services/commonService';
import * as PatientService from '../../../services/patientService';
import * as StatisticService from '../../../services/statisticService';
import * as ConfigService from '../../../services/configService';
import Upload from '../register/upload';
import * as PrintService from '../../../services/printService';
import { GetList } from '../../corporation/CorporationServices';
import * as SystemConfig from "../../../configure/systemConfig";
import NguonKhachHang from "../register/nguonkhachhang";
import UploadNew from '../../../components/MitaComponents/UploadFile/mitaUploadFile';
import { groupBy, isNullOrEmpty, renumber, uppercaseFirstLetter } from '../../../libs/util';
import Handlebars from "handlebars";
import handlebarHelper from '../../../libs/handlebarHelper';
import {default as num2words} from 'vn-num2words';
import { connect } from 'react-redux';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs
const { Option } = Select;
const regEx = new RegExp('(^[a-zA-Z])');
const lstMarketing = [
    {key : 'vanglai', value: 'Vãng Lai'},
    {key : 'facebook', value: 'Facebook'},
    {key : 'zalo', value: 'Zalo'},
    {key : 'website', value: 'Website'}
]
class patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupList: false,
            page: 1,
            size: 10,
            key: '',
            currentPage: 1,
            currentSize: 10,
            ngay: [moment(), moment()],
            expandableRow: [],
            visibleDate: false,
            visibleEdit: false,
            openDate: true,
            detail: {},
            lstCity: [],
            lstDistrict: [],
            lstWard: [],
            ward: null,
            district: null,
            city: null,
            diachi: '',
            disabledUpdate: false,
            defaultAge: null,
            defaultMonth: null,
            lstRelative: [],
            patientHistoryInfo: [],
            visibleCLS: false,
            coporator: null,
            listCoporators: [],
            onlyyear: false,
            sendby: [],
            managementID: "",
			mach: null,
			huyetap: null,
			chieucao: null,
			cannang: null,
			nhietdo: null,
            nhiptho: null,
            tenngt: '',
            idngt: '',
            mgsCopor: false,
            lstMarketing : [],
            marketing : [],
            banggia : 'default',
            visiblePopover : false,
        };
        this.validator = new SimpleReactValidator(ValidationCustom);
    }

    deleteRow(row) {
        ChiDinhService.XoaChiDinh(row.idChiDinh, row.chinhanh).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Xóa thành công" })
                if (Number(row.type) === 1 || row.isParent) {
                    if (this.modalCLS)
                        this.modalCLS.GetServicesHaveBeenSelected(true);
                }
            } else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
                if (this.modalCLS) {
                    this.modalCLS.GetServicesHaveBeenSelected(true);
                }
            }
        }).catch(err => {
            Actions.openMessageDialog('Lỗi', err)
        })
    }

    updateDataTable(key = '') {
        if (this.props.changePage) {
            this.props.changePage(key, this.state.currentPage, this.state.currentSize, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
            this.setState({ expandableRow: [] })
        }
    }

    componentDidMount() {
        // if (this.props.changePage) {
        //     this.props.changePage('', 1, 10, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
        // }
        this.loadCity();
        this.loadCoporation();
        this.loadMarketing();
        this.loadHTTKQ();
    }
    loadCoporation(key = "", page = 1, size = 10) {
        GetList(key, page, size).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listCoporators: res.data
                })
            }
        })
    }
    ListPatientRelative(mabn) {
        CommonService.ListPatientRelative(mabn).then(result => {
            if (result.isSuccess) {
                this.setState({
                    lstRelative: result.data,
                });
            }
        }).catch(err => {
            Actions.openMessageDialog("", err);
        })
    }
    loadCity(pageNumber = 1, pageSize = 1000) {
        AdministrativeService.GetAllCity(pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstCity: result.data,
                });
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ liệu thành phố bị lỗi' })
        })
    }
    loadDistrict(id, pageNumber, pageSize) {
        AdministrativeService.GetDistrictByCityId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {

                this.setState({
                    lstDistrict: result.data,
                    lstWard: []
                });
            }
            else {
                this.form.setFieldsValue({
                    district: null,
                    ward: null
                });
                this.setState({
                    lstDistrict: [],
                    lstWard: [],
                    district: null,
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ quận huyện thị bị lỗi' })
        })
    }

    loadWard(id, pageNumber, pageSize) {
        AdministrativeService.GetWardByDistrictId(id, pageNumber, pageSize).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstWard: result.data,
                });

            }
            else {
                this.form.setFieldsValue({
                    ward: null
                });
                this.setState({
                    loadWard: [],
                    ward: null
                })
            }
        }).catch(err => {
            notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }


    loadWardWithCallback(id, cb) {
        AdministrativeService.GetWardByDistrictId(id, 1, 1000).then(result => {
            if (result.isSuccess && result.data.length > 0) {
                this.setState({
                    lstWard: result.data,
                }, cb);

            }
            else {
                this.form.setFieldsValue({
                    ward: null
                });
                this.setState({
                    loadWard: [],
                    ward: null
                })
            }

        }).catch(err => {
            notification.warning({ message: 'Tải dữ phường/xã thị bị lỗi' })
        })
    }
    ExportDanhSachBNDangKy() {
        Actions.setLoading(true);
        StatisticService.ExportDanhSachBenhNhanDaDangKy('', 1, 10000, this.state.ngay[0].format('YYYY-MM-DD'), this.state.ngay[1].format('YYYY-MM-DD'),
            this.state.makho, this.state.key)
            .then((response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this._hiddenLink.href = url;
                    this._hiddenLink.download = "ThongKeDanhSachBenhNhanDaDangKy";
                    this._hiddenLink.click();
                })
                Actions.setLoading(false);
            }).catch(err => {
                Actions.setLoading(false);
            })
    }
    setExpandable(index) {
        let lst = this.state.expandableRow;
        if (lst.length === 0) {
            this.setState({ expandableRow: [index] });
            return;
        }
        let old = lst.indexOf(index);
        if (old < 0) {
            this.setState({ expandableRow: [index] })
        }
        else {
            this.setState({ expandableRow: [] })
        }
    }
    SetData(row) {
        if (this.props.SetData) {
            this.props.SetData(row);
        }
        if(row.marketing !== ''){
            var lst = row.marketing.split(';');
            this.setState({
                marketing : lst
            })
        }
        this.setState({
            diachi: row.address,
            mabn: row.patientID, hoten: row.patientName,
            gender: Number(row.gender), dob: row.birthYear,
            ward: !isNullOrEmpty(row.wardID) ? Number(row.wardID) : null, district: !isNullOrEmpty(row.districtID) ? Number(row.districtID) : null,
            city: !isNullOrEmpty(row.cityID) ? Number(row.cityID) : null, sodienthoai: row.phone,
            email: row.email, cmnd: row.cardId,
            defaultAge: new moment().diff(moment(row.birthYear), 'years'),
            defaultMonth: new moment().diff(moment(row.birthYear), 'months'),
            coporator: row.coporator, onlyyear: row.onlyyear, sendby: row.sendby,
            managementID: row.managementID,
            banggia : row.listSubPatient.length > 0 ? row.listSubPatient[0].banggia : 'default',
        }, () => {
            if (!isNullOrEmpty(row.cityID)) {
                this.loadDistrict(row.cityID);
                if (!isNullOrEmpty(row.districtID)) {
                    this.loadWardWithCallback(row.districtID, () => {
                        let months = new moment().diff(this.state.dob !== '' ? moment(this.state.dob) : moment(), 'months');
                        this.form.setFieldsValue({
                            diachi: row.address,
                            hoten: row.patientName,
                            district: this.state.district ? +this.state.district : null,
                            ward: this.state.ward ? +this.state.ward : null,
                            email: this.state.email,
                            sodienthoai: row.phone,
                            cmnd: this.state.cmnd,
                            dob: moment(this.state.dob),
                            age: months >= 24 ? (moment().year() - moment(this.state.dob).year()) : 0,
                            agecalbymonth: months >= 24 ? null : months,
                            coporator: this.state.coporator,
                            sendby: this.state.sendby,
                        });
                    });
                } else {
                    this.setDataWithoutCityDistrict(row);
                }
            } else {
                this.setDataWithoutCityDistrict(row);
            }
        })
    }

    setDataWithoutCityDistrict(row) {
        let months = new moment().diff(row.birthYear !== '' ? moment(row.birthYear) : moment(), 'months');
        this.form.setFieldsValue({
            diachi: row.address,
            hoten: row.patientName,
            district: null,
            ward: null,
            email: row.email,
            sodienthoai: row.phone,
            cmnd: row.cardId,
            dob: moment(row.birthYear),
            age: months >= 24 ? (moment().year() - moment(row.birthYear).year()) : 0,
            agecalbymonth: months >= 24 ? null : months,
            coporator: row.coporator,
            sendby: row.sendby,
        });
    }

    onChangeDob = e => {
        var date = e;
        if (date.isValid() && this.form !== null) {
            let months = new moment().diff(moment(e), 'months')

            if (months >= 24) {
                this.form.setFieldsValue({
                    age: new moment().year() - moment(e).year(),
                    agecalbymonth: ''
                })
            } else {
                this.form.setFieldsValue({
                    agecalbymonth: months,
                    age: new moment().diff(moment(e), 'years')
                })
            }
        }
    }
    onchangeAge = e => {
        if (e === null)
            e = 0;
        this.setState({ dob: moment(new moment().subtract(e, 'years').format("YYYY-01-01")) })
        if (this.form !== null && e !== null)
            this.form.setFieldsValue({
                dob: moment(new moment().subtract(e, 'years').format("YYYY-01-01")),
                agecalbymonth: '',
                age: e,
            })

    }
    onchangeAgeMonth = e => {
        if (this.form !== null && e !== null) {
            let dob = new moment().subtract(e, 'months').format("YYYY-MM-01");
            this.setState({ dob: moment(dob) })
            this.form.setFieldsValue({
                dob: moment(dob),
                age: e === 24 ? new moment().diff(moment(dob), 'years') : 0,
                agecalbymonth: e === 24 ? 0 : e,
            })
        }

    }
    getListHistory(patientID) {
        PatientService.getListHistory(patientID)
            .then((respone) => {

                if (respone.isSuccess === true) {
                    if (respone.item && respone.item.trans)
                        this.setState({ patientHistoryInfo: respone.item.trans })
                } else {
                    Actions.openMessageDialog("Error " + respone.err.msgCode, respone.err.msgString.toString());
                }
            }).catch((ex) => {
                Actions.openMessageDialog("lỗi", ex);
            })
    }
    async updateBN() {
        let numberError = 0;
		if (this.props.marketingRequired && this.state.marketing.length == 0) {
			Actions.openMessageDialog("Thông báo", "Vui lòng chọn kênh khách hàng");
			return;
		}
        await this.form.validateFields().then(values => {
            if (values.errorFields)
                numberError = values.errorFields.length
        }).catch((ex) => {
            if (ex.errorFields)
                numberError = ex.errorFields.length
        })
        if (numberError === 0 && this.validator.allValid()) {
            const data = {
                patientID: this.state.mabn,
                patientName: this.state.hoten,
                birthDay: moment(this.state.dob).format('YYYY/MM/DD'),
                gender: this.state.gender,
                phone: this.state.sodienthoai,
                email: this.state.email,
                cardId: this.state.cmnd,
                ward: this.state.ward,
                district: this.state.district,
                city: this.state.city,
                address: this.state.diachi,
                coporator: this.state.coporator,
                sendby: this.state.sendby,
                onlyyear: this.state.onlyyear,
                managementID: this.state.managementID,
                referID: this.state.idngt,
                referName: this.state.tenngt,
                DSTInfo: {
                    mach: this.state.mach,
                    nhietdo: this.state.nhietdo,
                    huyetap: this.state.huyetap,
                    cannang: this.state.cannang,
                    chieucao: this.state.chieucao,
                    nhiptho: this.state.nhiptho,
                },
                marketing : this.state.marketing.join(';'),
            }
            this.nguonKhachHang && this.nguonKhachHang.updateInfo();
            PatientService.Update(data).then(result => {
                    if (result.isSuccess) {
                        if (this.props.changePage)
                            this.props.changePage('', this.state.currentPage, 10, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                        notification.success({ message: 'Cập nhật thành công!' })
                    }
                    else {
                        notification.warning({ message: result.err.msgString });
                    }
                }).catch(err => {
                    notification.warning({ message: 'Cập nhật thông tin bị lỗi!' })
                })
            
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    deleteRecord(ID, name) {
        Actions.setLoading(true);
        PatientService.cancelExam(ID).then((respone) => {
            if (respone.isSuccess) {
                notification.success({ message: `Đã hủy đăng ký khám cho bệnh nhân: ${name}` });
                this.updateDataTable(this.state.key);
            } else {
                Actions.openMessageDialog("Lỗi", respone.err.msgString);
            }
            Actions.setLoading(false);
        })

    }
    AddRow(lstData) {

        if (lstData === [])
            return;
        const lstChiDinhBenhNhan = lstData.map(x => ({
            ...x,
            idChiDinh: x.idChiDinh !== undefined ? x.idChiDinh : "0",
            madoituong: "2",
            mavp: x.id.toString(),
            ngay: this.state.detail.dateIn,
            soluong: x.soluong,
            isNew: true,
            packageId: x.packageId !== undefined ? x.packageId : "0"
        }));
        if (lstChiDinhBenhNhan.length === 0) {
            return;
        }

        Actions.setLoading(true);
        const patient = {
            'code': this.state.detail.patientID,
            'department': this.state.detail.departmentID,
            'subjectCode': '2',
            'managerId': this.state.detail.managementID,
            'enterCode': this.state.detail.enterCode,
        }
        const data = {
            "listChiDinh": lstChiDinhBenhNhan,
            "patient": patient,
        }
        ChiDinhService.LuuChiDinh(data).then(result => {
            if (result.isSuccess) {
                notification.success({ message: "Lưu thành công" });
                if (this.props.changePage) {
                    this.props.changePage('', 1, 10, this.state.ngay[0].format('YYYY/MM/DD'), this.state.ngay[1].format('YYYY/MM/DD'));
                }
                this.modalCLS.reset()
            }
            else {
                Actions.openMessageDialog('Lỗi', result.err.msgString);
            }
            Actions.setLoading(false);
        }).catch(err => {
            Actions.setLoading(false);
        })
    }
    
    printRegister(maql) {
        PrintService.printReceptionReceipt(maql)
        .then((result) => {
            if (isNullOrEmpty(result.item.template)) {
                Actions.openMessageDialog("Không có thông tin để in", "Không có dịch vụ hoặc các dịch vụ đã được thực hiện, lấy mẫu...");
                return;
            } else {
                // const template = Handlebars.compile(result.item.template);
                // const listTestGroup = groupBy(result.item.data.listTest.listIndication, "tenloaiXN");
                // const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, key, value: listTestGroup[key] }));
                // const resultGroupArrRenumber = renumber(resultGroupArr);
                // result.item.data.listTest.listIndication = resultGroupArrRenumber;
                let templateMaster = "";
				var finalData = result.item.data;
				const listOrderId = result.item.listOrderId; // Danh sách orderId
				var listTestGroupByOrderId = groupBy(result.item.data.listTest.listIndication, "orderid"); // Danh sách chỉ định xét nghiệm đã phân theo orderid
				Object.keys(listTestGroupByOrderId).forEach(orderId => {
					let listTestBelongToGroup = listTestGroupByOrderId[orderId];
					const listTestGroup = groupBy(listTestBelongToGroup, "tenloaiXN");
					const resultGroupArr = Object.keys(listTestGroup).map((key) => ({ key, value: listTestGroup[key] }));
					const resultGroupArrRenumber = renumber(resultGroupArr);
					let totalMoney = listTestBelongToGroup.map(x => x.sotien).reduce((sum, price) => sum + price, 0);
					let dataInfo = {
						listIndication: resultGroupArrRenumber,
						sidBarcode: listOrderId?.find(x => x.label == orderId)?.value,
						title: `${finalData.listTest.title?.toUpperCase() ?? "PHIẾU ĐĂNG KÝ XÉT NGHIỆM"}`,
						amount: totalMoney,
						amountString: totalMoney ? uppercaseFirstLetter(num2words(totalMoney)) + " đồng" : "",
					};
					let keyName = `test${orderId}`;
					var tempData = {...finalData, [`${keyName}`]: dataInfo};
					finalData = tempData;
					templateMaster += result.item.subTemplate.replaceAll("data.listTest", `data.${keyName}`); // Thêm template chỉ định xét nghiệm phân theo orderId và theo loại
				});
				const template = Handlebars.compile(result.item.template.replace("#listDangKyXetNghiem", templateMaster));
				result.item.data = finalData;
                Handlebars.registerHelper(handlebarHelper);
                Actions.DoPrint(template(result.item));
            }
        })
    }

    printIsPaid(mabn, maql, ngay){
        PrintService.PrintIsPaid(mabn, maql, ngay)
        .then((result) => {
            if (isNullOrEmpty(result.item)) {
                Actions.openMessageDialog("Thông báo", "Không có thông tin để in");
                return;
            }
            Actions.DoPrint(result.item)
        })
    }

    checkPassport = (value) => {
        return regEx.test(value);
    }
    getDauSinhTon = (maql) => {
        if (maql !== null && maql !== undefined && maql !== "") {
            PatientService.GetDauSinhTon(maql).then(res => {
                if (res.isSuccess) {
                    this.setState({
                        mach: res.item.mach,
                        huyetap: res.item.huyetap,
                        chieucao: res.item.chieucao,
                        cannang: res.item.cannang,
                        nhietdo: res.item.nhietdo,
                        nhiptho: res.item.nhiptho,
                    })
                }
            }).catch({ })
        }
    }
    
    loadNguoiGioiThieu(data) {
        this.setState({ key: data });
        return PatientService.GetListNguoiGioiThieu(data, false, 1, 20);
    }

    SelectNguoiGioiThieu(data) {
        this.setState({ idngt: data.id, tenngt: data.hoten });
    }

    getNguoiGioiThieu(mabn, maql) {
        PatientService.getNguoiGioiThieuTheoMaQuanLy(mabn, maql).then(res => {
            if (res.isSuccess) {
                this.setState({ idngt: res.item.id, tenngt: res.item.hoten });
            }
        }).catch(() => { })
    }

    loadMarketing(){
        ConfigService.GetKenhKhachHang(1,100,false).then(result => {
            if(result.isSuccess){
                this.setState({
                    lstMarketing : result.data
                })
            }else {
                notification.warning({ message: result.err.msgString });
            }
        }).catch(err => {

        })
    }


    addMarkerting = (e) => {
        let lst = this.state.marketing;
        if(e.target.checked){
            lst.push(e.target.value.toString());
        } else { 
            lst = lst.filter((item) => item !== e.target.value.toString())
        }
        this.setState({ marketing : lst });
    }
	
	addNotExistCopr(value, title) {
		let data = this.state.listCoporators;
		let found = data.find(x => x.ma === value);
		if (found === undefined) {
			data.unshift({
				ma: value,
				ten: title,
			});
			this.setState({
				listCoporators: data,
			})
		}
	}

    checkBangGia = (info) => {
        if(info.coporatorID !== null && info.coporatorID !== this.state.coporator){
            Actions.openMessageDialog("Cảnh báo", `Việc thay đổi nguồn khách hàng không làm thay đổi bảng giá đã chọn.Vui lòng xóa tất cả các dịch vụ sau đó chọn bảng giá mới.`)
        }
    }

    getPatientInfo(row) {
        CommonService.GetPatientDetail(row.patientID).then(result => {
            if (result.isSuccess) {
                const data = result.item;
                row.patientID = data.patientID;
                row.patientName = data.patientName;
                row.birthYear = data.birthDay;
                row.address = data.address;
                row.cityID = data.city;
                row.districtID = data.district;
                row.wardID = data.ward;
                row.onlyyear = data.onlyyear;
                row.email = data.email;
                row.phone = data.phone;
                row.cardId = data.cardId;
                this.SetData(row);
                this.ListPatientRelative(row.patientID);
                this.getListHistory(row.patientID);
                this.getDauSinhTon(row.managementID);
                this.getNguoiGioiThieu(row.patientID, row.managementID);
                this.addNotExistCopr(row.coporator, row.nguonkhachhang);
                this.setState({ visibleEdit: true, detail: row });
            } else {
                notification({ message: result.err.msgString });
            }
        }).catch(err => {
            notification({ message: err.msgString });
        })
    }
    
    loadHTTKQ(key = "", page = 1, size = 10) {
        const getAll = true;
        ConfigService.getHTTKQ(key, page, size, getAll).then(res => {
            if (res.isSuccess) {
                this.setState({
                    listHTTKQ: res.data
                }, () => this.forceUpdate())
            }
        })
    }

    render() {
        const columnNguoiGioiThieu = [
            {
                title: 'Mã',
                dataIndex: 'id',
                key: 'id',
                width: 80,
            },
            {
                title: 'Tên',
                dataIndex: 'hoten',
                key: 'hoten',
                width: 250,
            },
            {
                title: 'Điện thoại',
                dataIndex: 'dienthoai',
                key: 'dienthoai',
                width: 100,
            },
            {
                title: 'Giới tính',
                dataIndex: 'gioitinh',
                key: 'gioitinh',
                width: 80,
            },
        ];
        return (
            <div>
                <a href="#download" style={{ visibility: "hidden", display: 'none' }} ref={(c) => { this._hiddenLink = c }} >download</a>

                <div className="input-group">
                    <input autoFocus={true} className='form-control' style={{ marginRight: '15px' }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this.setState({ currentPage: 1 }, () => { this.updateDataTable(this.state.key) })
                            }
                        }}
                        placeholder="Nhập tên cần tìm" onChange={(e) => this.setState({ key: e.target.value })} />
                    <Popover id="datePickerRegister"
                        content={
                            <div style={{ width: '560px', padding: 0 }}>
                                <RangePicker format="DD-MM-YYYY" value={this.state.ngay} allowClear={false} open={this.state.openDate}
                                    placement="bottomRight" locale={viVN} onChange={(value => this.setState({ ngay: value }, () =>
                                        this.setState({ currentPage: 1 }, () => { this.updateDataTable(this.state.key) })
                                    ))}
                                />
                                <div style={{ height: '281px' }}></div>
                            </div>
                        }
                        title="Chọn ngày"
                        trigger="click"
                        visible={this.state.visibleDate}
                        onVisibleChange={(e) => { this.setState({ visibleDate: e, openDate: e }) }}
                        placement="bottomRight"

                    >
                        <button className="btn btn-secondary" style={{ marginRight: '15px', marginTop: '-1px' }}
                            onClick={() => { this.setState({ visibleDate: true }) }}><i className="fa fa-calendar-alt"></i></button>
                    </Popover>
                    <div>
                        <button className="btn btn-color"
                            onClick={() => this.setState({ currentPage: 1 }, () => { this.updateDataTable(this.state.key) })}>
                            <i style={{ marginRight: 5 }} className="fa fa-search" /> Tìm</button>
                        <button type="primary" style={{ marginLeft: 15, display: 'none' }}
                            onClick={() => this.ExportDanhSachBNDangKy()}><i style={{ marginRight: 5 }} className="fas fa-download" />Xuất Excel</button>
                    </div>
                </div>

                <div className="mb-1 mt-2">
                    <table className="registList table table-striped table-hover table-bordered w-100" style={{ marginBottom: 0 }}>
                        <tbody>
                            {this.props.dataSource && this.props.dataSource.map((row, index) => (
                                <tr key={'1' + index} style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        this.getPatientInfo(row);
                                    }}>
                                    <td><div>
                                        {row.atHome && <><Tag color="magenta">Mẫu tại nhà</Tag><br/></>}
                                        <span><strong>{row.patientName}</strong></span><br />
                                        <span>{row.patientID}-{row.year}</span>
                                    </div></td>
                                    <td>{moment(row.dateIn, 'YYYY/MM/DD HH:mm').format(SystemConfig.FORMAT_DATE_TIME).toString()}</td>
                                    <td onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                                        style={{ width: '130px' }}
                                    >
                                        <Button className={'registButonTable'} style={{ marginLeft: 10 }}
                                            onClick={e => this.printRegister(row.managementID)}
                                        ><i className="fa fa-print" /></Button>
                                        
                                        {/* <Popover
                                            content={
                                                <div className='d-flex flex-column'>
                                                    <button className='btn btn-color w-100 mb-2' onClick={() => { this.printRegister(row.managementID); this.setState({ visiblePopover: false }) }}>In đăng kí</button>
                                                    <button className='btn btn-secondary w-100 mb-2' onClick={() => { this.printIsPaid(row.patientID,row.managementID,row.dateIn); this.setState({ visiblePopover: false }) }}>In chỉ định</button>
                                                </div>
                                            }
                                            trigger="click"
                                            visible={this.state.visiblePopover && row.managementID === this.state.managementID}
                                            onVisibleChange={() => this.setState({ visiblePopover: !this.state.visiblePopover })}
                                        >
                                            <Button 
                                                onClick={() => { this.setState({ managementID: row.managementID, visiblePopover: true })}}
                                                className='registButonTable' 
                                                style={{ marginLeft: 10 }}>
                                                <i className='fa fa-print' /></Button>
                                        </Popover> */}
                                        <Popconfirm disabled={row.status !== 'Chưa đóng tiền'}
                                            title={<div style={{ width: '200px' }}>Xác nhận xóa lần đăng ký này?</div>}
                                            placement="topRight"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onConfirm={() => { this.deleteRecord(row.managementID, row.patientName) }}
                                            okText="Đồng ý" cancelText="Hủy">
                                            <Button className={'registButonTable'} style={{ marginLeft: 10 }} danger disabled={!row.allowDelete}
                                            // {row.status !== 'Chưa đóng tiền' || row.done !== ''}
                                            >
                                                <i className="fa fa-trash" /></Button>
                                        </Popconfirm>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="w-100">
                    {this.props.dataSource && this.props.dataSource.length > 0 &&
                        <Pagination
                            simple={true}
                            showLessItems={true}
                            hideOnSinglePage={true}
                            position={['bottomCenter']}
                            responsive={true}
                            current={this.state.currentPage}
                            size={this.state.currentSize}
                            style={{ textAlignLast: "center", marginTop: "10px" }}
                            total={this.props.totalRecords}
                            showTotal={total => `Tổng: ${total}`}
                            onChange={(page, size) => {

                                this.setState({ currentPage: page, currentSize: size },
                                    () => { this.updateDataTable(this.state.key) })
                            }}
                            onShowSizeChange={(page, size) => {

                                this.setState({ currentPage: 1, currentSize: size }, () => {
                                    this.updateDataTable(this.state.key)
                                })
                            }}
                            showSizeChanger={true}
                        />
                    }
                </div>
                <Form ref={(c) => this.form = c}>{this.state.visibleEdit &&
                    <Modal
                        className='modal-tab-header-services'
                        visible={this.state.visibleEdit}
                        onCancel={() => this.setState({ visibleEdit: false })}
                        width={1400}
                    >
                        <div style={{ padding: '10px', background: '#6FA8DC', marginBottom: '0px' }} className="font-weight-bold text-center h4">{this.state.detail.patientID} {this.state.detail.patientName}</div>
                        <Tabs className='tab-header-services' centered={true}>
                            <TabPane style={{ border: 'none', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '24px' }} tab={"THÔNG TIN BỆNH NHÂN"} key="1">
                                <div className="border-register">
                                    <div className="row">
                                        <div className="col-lg-4 mt-1">
                                            <label className='required-field'>Họ và tên</label>
                                            <Form.Item name="hoten"
                                                rules={[{ message: 'Họ tên không được để trống' }]}>
                                                <Input disabled={this.state.disabledUpdate} style={{ textTransform: 'uppercase' }}
                                                    onChange={(e) => { this.setState({ hoten: e.target.value.toUpperCase() }) }}
                                                    value={this.state.hoten.toUpperCase()} />
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <div className="row no-gutters">
                                                <div className="col-6">
                                                    <label className='required-field'>Giới tính</label>
                                                    <Form.Item>
                                                        <Select disabled={this.state.disabledUpdate}
                                                            onChange={(e) => this.setState({ gender: e })}
                                                            value={this.state.gender}>
                                                            <Option value={0}>Nam</Option>
                                                            <Option value={1}>Nữ</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Tooltip className='mr-2' title="Chỉ lưu năm sinh">
                                                        <Checkbox checked={this.state.onlyyear} onChange={() => this.setState({ onlyyear: !this.state.onlyyear })}></Checkbox>
                                                    </Tooltip>
                                                    <label className='required-field'>{this.state.onlyyear ? "Năm sinh" : "Ngày sinh"}</label>
                                                    <Form.Item name="dob" rules={[{ required: true, message: 'Vui lòng nhập ngày sinh!' }]} >
                                                        <DatePicker allowClear={false} disabled={this.state.disabledUpdate} format={this.state.onlyyear ? "YYYY" : "DD-MM-YYYY"} className="w-100" onChange={(e) => { this.setState({ dob: e }); this.onChangeDob(e); }}
                                                            value={moment(this.state.dob)} placeholder="dd-MM-YYYY"
                                                            locale={viVN} picker={this.state.onlyyear && "year"}
                                                            disabledDate={(current) => {
                                                                return current && current >= moment().endOf('day');
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <div className="row no-gutters">
                                                <div className="col-6">
                                                    <label>Tuổi</label>
                                                    <Form.Item name="age" initialValue={this.state.defaultAge} min={0} disabled={this.state.disabledUpdate} >
                                                        <InputNumber placeholder="Tuổi" defaultValue={this.state.defaultAge} min={0} disabled={this.state.disabledUpdate} className='w-100' max={120} onChange={(e) => {
                                                            this.onchangeAge(e)
                                                        }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <label>Tháng tuổi</label>
                                                    <Form.Item initialValue={Number(this.state.defaultMonth) >= 24 ? '' : this.state.defaultMonth}
                                                        name="agecalbymonth"  >
                                                        <InputNumber className='w-100' disabled={this.state.disabledUpdate} placeholder="Tháng" min={0} max={24} onChange={(e) => {
                                                            this.onchangeAgeMonth(e)
                                                        }} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <label className={this.props.administrativeRequired ? "required-field" : ""}>Tỉnh/TP</label>
                                            <Form.Item>
                                                <Select onChange={(value) => {
                                                    this.setState({
                                                        city: value, lstDistrict: [], lstWard: [],
                                                        district: null, ward: null
                                                    });
                                                    this.form.setFieldsValue({
                                                        district: null,
                                                        ward: null
                                                    })
                                                    value && this.loadDistrict(value)
                                                    // let address = '';
                                                    // for (let city of this.state.lstCity) {
                                                    //     if (value.toString() === city.value.toString()) {
                                                    //         address += city.label;
                                                    //     }
                                                    // }
                                                    // this.setState({ diachi: address });
                                                    // this.form.setFieldsValue({
                                                    //     diachi: address
                                                    // })
                                                }}
                                                    showSearch disabled={this.state.disabledUpdate}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    value={this.state.city}
                                                    allowClear={!this.props.administrativeRequired}
                                                >
                                                    {this.state.lstCity.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.value}>{row.label}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.props.administrativeRequired && this.validator.message("Tỉnh/TP", this.state.city, "selection")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <label className={this.props.administrativeRequired ? "required-field" : ""}>Quận/Huyện</label>
                                            <Form.Item name="district">
                                                <Select disabled={this.state.disabledUpdate}
                                                    allowClear={!this.props.administrativeRequired}
                                                    value={this.state.district}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            district: value,
                                                            lstWard: [],
                                                            ward: null
                                                        });
                                                        this.form.setFieldsValue({
                                                            ward: null,
                                                        })
                                                        this.loadWard(value, 1, 1000);
                                                        // let address = '';
                                                        // for (let district of this.state.lstDistrict) {
                                                        //     if (value.toString() === district.value.toString()) {
                                                        //         address += district.label + ', ';
                                                        //         break;
                                                        //     }
                                                        // }
                                                        // if (this.state.lstCity)
                                                        //     for (let city of this.state.lstCity) {
                                                        //         if (this.state.city.toString() === city.value.toString()) {
                                                        //             address += city.label;
                                                        //             break;
                                                        //         }
                                                        //     }
                                                        // this.setState({ diachi: address });
                                                        // this.form.setFieldsValue({
                                                        //     diachi: address
                                                        // })
                                                    }}>
                                                    {this.state.lstDistrict.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.value}>{row.label}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.props.administrativeRequired && this.validator.message("Quận/Huyện", this.state.district, "selection")}
                                            </Form.Item>

                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <label className={this.props.administrativeRequired ? "required-field" : ""}>Phường/Xã</label>
                                            <Form.Item name="ward">
                                                <Select disabled={this.state.disabledUpdate}
                                                    value={this.state.ward}
                                                    allowClear={!this.props.administrativeRequired}
                                                    onChange={(value) => {
                                                        this.setState({ ward: value });
                                                        let obj = this.form.getFieldsValue();
                                                        // let address = '';
                                                        // for (let ward of this.state.lstWard) {
                                                        //     if (value.toString() === ward.value.toString()) {
                                                        //         address += ward.label + ', ';
                                                        //         break;
                                                        //     }
                                                        // }
                                                        // if (this.state.lstDistrict)
                                                        //     for (let dis of this.state.lstDistrict) {
                                                        //         if (obj.district.toString() === dis.value.toString()) {
                                                        //             address += dis.label + ', ';
                                                        //             break;
                                                        //         }
                                                        //     }
                                                        // if (this.state.lstCity)
                                                        //     for (let city of this.state.lstCity) {
                                                        //         if (this.state.city.toString() === city.value.toString()) {
                                                        //             address += city.label;
                                                        //             break;
                                                        //         }
                                                        //     }
                                                        // this.setState({ diachi: address });
                                                        // this.form.setFieldsValue({
                                                        //     diachi: address
                                                        // })
                                                    }}>
                                                    {this.state.lstWard.map((row, index) => {
                                                        return (
                                                            <Option key={index} value={row.value}>{row.label}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {this.props.administrativeRequired && this.validator.message("Phường/Xã", this.state.ward, "selection")}
                                            </Form.Item>

                                        </div>
                                        <div className="col-8 mt-1">
                                            <label>Địa chỉ</label>
                                            <Form.Item name="diachi">
                                                <Input disabled={this.state.disabledUpdate}
                                                    placeholder="Nhập địa chỉ"
                                                    onChange={(e) => this.setState({ diachi: e.target.value })}
                                                    value={this.state.diachi} />
                                            </Form.Item>
                                        </div>
                                        {/* <div className="col-4 mt-2">
                                            <label>Người giới thiệu</label>
                                            <Form.Item>
                                                <AutocompletetableNoGroup
                                                    placeholder={`${this.state.tenngt ? this.state.tenngt : "Nhập thông tin người giới thiệu"}`}
                                                    width='100%'
                                                    tablewidth={500}
                                                    onSearch={(data) => this.loadNguoiGioiThieu(data)}
                                                    onSelect={(data) => this.SelectNguoiGioiThieu(data)}
                                                    headers={columnNguoiGioiThieu}
                                                    keyValue={'id'}
                                                    value={this.state.tenngt}
                                                    showRemove={this.state.tenngt !== '' && this.state.tenngt !== undefined && this.state.tenngt !== null}
                                                    CleanText={() => {
                                                        this.setState({ idngt: '', tenngt: '' });
                                                    }}
                                                />
                                            </Form.Item>
                                        </div> */}
                                        <div className="col-lg-4 mt-1">
                                            <label>Điện thoại</label>
                                            <Form.Item name="sodienthoai" rules={[{ max: 11, message: 'Số điện thoại phải ít hơn 11 ký tự' }]}>
                                                <Input min={0} maxLength={11} disabled={this.state.disabledUpdate}
                                                    placeholder="Nhập số điện thoại"
                                                    onKeyPress={(e) => {
                                                        if (e.key === " " || isNaN(e.key) === true)
                                                            e.preventDefault();
                                                    }}
                                                    onChange={(e) => this.setState({ sodienthoai: e.target.value })}
                                                    value={this.state.sodienthoai} />
                                                {this.validator.message("Số điện thoại", this.state.sodienthoai, "phonenumber|maxLen:11,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <label>{this.checkPassport(this.state.cmnd) ? "Hộ chiếu" : "Căn cước công dân"}</label>
                                            <Form.Item name="cmnd" rules={[{ max: 20, message: 'Căn cước công dân phải ít hơn 20 ký tự' }]}>
                                                <Input min={0} disabled={this.state.disabledUpdate}
                                                    placeholder="Nhập căn cước công dân"
                                                    // onKeyPress={(e) => {
                                                    //     if (e.key === " " || isNaN(e.key) === true)
                                                    //         e.preventDefault();
                                                    // }}
                                                    onChange={(e) => this.setState({ cmnd: e.target.value })}
                                                    value={this.state.cmnd} />
                                                    {this.state.cmnd && this.checkPassport(this.state.cmnd) ?
                                                        this.validator.message("Hộ chiếu", this.state.cmnd, "passport|maxLen:11,num")
                                                        : this.validator.message("CMND/CCCD", this.state.cmnd, "cmnd|maxLen:20,num")}
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-4 mt-1">
                                            <label>Email</label>
                                            <Form.Item name="email"
                                                rules={[{ type: 'email', message: 'Email không đúng định dạng' }]}>
                                                <Input type="email" disabled={this.state.disabledUpdate}
                                                    value={this.state.email}
                                                    onChange={(e) => this.setState({ email: e.target.value })}
                                                    placeholder="Nhập email" />
                                                {this.validator.message("Email", this.state.email, "email")}
                                            </Form.Item>
                                        </div>
                                        {this.state.detail.subjectCode === '1' &&
                                            <React.Fragment>
                                                <div className="col-lg-4 mt-1">
                                                    Số thẻ BHYT: {this.state.detail.cardNumber}
                                                </div>
                                                <div className="col-lg-4 mt-1">
                                                    Thời hạn: {moment(this.state.detail.cardFrom).format('DD/MM/YYYY')} - {moment(this.state.detail.cardTo).format('DD/MM/YYYY')}
                                                </div>
                                                <div className="col-lg-4 mt-1">
                                                    BV BHYT: {this.state.detail.cardAddress}
                                                </div>
                                            </React.Fragment>}
                                        {/* <div className="col-lg-6 mt-1">
                                            <label>Nguồn khách hàng <Tooltip title="Thuộc về thông tin khách hàng"><i className='far fa-question-circle'></i></Tooltip></label>
                                            <Popconfirm
                                                title={`Sau khi cập nhật "Nguồn khách hàng" thì bảng giá dịch vụ đã đăng ký không thay đổi`}
                                                visible={this.state.mgsCopor}
                                                showCancel={false}
                                                onConfirm={() => {this.setState({mgsCopor : false})}}
                                                onVisibleChange={e => {
                                                    if (!e)
                                                        this.setState({ mgsCopor: e })
                                                }}
                                            >
                                                <Form.Item name="coporator">
                                                    <Select value={this.state.detail.coporator} placeholder="Chọn nguồn khách hàng"
                                                        onChange={(e) => {
                                                            this.setState({ coporator: e, mgsCopor:true })
                                                        }}
                                                        showSearch
                                                        onSearch={(value) => this.loadCoporation(value)}>
                                                        {this.state.listCoporators && this.state.listCoporators.map((copr, index) => {
                                                            return (
                                                                <Option key={index} value={copr.ma}>{copr.ten}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Popconfirm>
                                        </div> */}
                                        <div className="col-lg-4 mt-1">
                                            <label>Hình thức trả kết quả<Tooltip title="Chỉ áp dụng cho lần đăng ký này">&nbsp;<i className='far fa-question-circle' /></Tooltip></label>
                                            <Form.Item name="sendby">
                                                <Select value={this.state.detail.sendby} placeholder="Chọn hình thức trả kết quả"
                                                    onChange={(value) => {
                                                        this.setState({ sendby: value })
                                                    }}
                                                    showSearch
                                                    onSearch={(e) => this.loadHTTKQ(e)}
                                                    mode='multiple'
                                                    filterOption={(input, option) =>{
                                                        return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                                                                option.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                    }}>
                                                    {
                                                        this.state.listHTTKQ && this.state.listHTTKQ.map((x, index) => {
                                                            return (
                                                                <Option key={x.id} value={x.keyValue}>{x.keyName}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-12 mt-1 d-flex flex-wrap justify-content-between">
                                            <div style={{ width: "20%" }}>
                                                <label>Chiều cao</label>
                                                <Form.Item>
                                                    <InputNumber className="w-100" placeholder="Cm" value={this.state.chieucao} min={0} maxLength={5}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ chieucao: e ? e.toString() : '' })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <label>Cân nặng</label>
                                                <Form.Item>
                                                    <InputNumber className="w-100" placeholder="Kg" value={this.state.cannang} min={0} maxLength={5}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ cannang: e ? e.toString() : '' })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <label>Huyết áp</label>
                                                <Form.Item>
                                                    <Input className="w-100" placeholder="mmHg" value={this.state.huyetap} maxLength={9}
                                                        onChange={(e) => {
                                                            this.setState({ huyetap: e.target.value ? e.target.value.toString().replace(/[a-zA-Z~!@#$%^&*()_+*.{}|;:,?'"\-\\=]/g, "") : '' })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <label>Mạch</label>
                                                <Form.Item>
                                                    <InputNumber className="w-100" placeholder="Lần/phút" value={this.state.mach} min={40} maxLength={5} max={250}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ mach: e ? e.toString() : '' })
                                                        }} />
                                                </Form.Item>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <label>Nhiệt độ</label>
                                                <Form.Item>
                                                    <InputNumber className="w-100" placeholder="Nhiệt độ" value={this.state.nhietdo} min={35} maxLength={5} max={41}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ nhietdo: e ? e.toString() : '' })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-1 d-flex flex-wrap justify-content-between">
                                            <div style={{ width: "50%" }}>
                                                <label>Nhịp thở</label>
                                                <Form.Item>
                                                    <InputNumber className="w-100" placeholder="Lần/phút" value={this.state.nhiptho} maxLength={5}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ nhiptho: e ? e.toString() : '' })
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ width: "50%" }}>
                                                <label>BMI</label>
                                                <Form.Item>
                                                    <InputNumber
                                                    className="w-100" 
                                                    placeholder="BMI" 
                                                    value={this.state.cannang && this.state.chieucao && Number(this.state.cannang/(Math.pow(this.state.chieucao/100,2))).toFixed(2)} 
                                                    readOnly={true}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-1">
							                <label className={this.props.marketingRequired ? "required-field" : ""}>Kênh khách hàng</label>
                                            <Form.Item name="marketing" >
                                            {this.state.lstMarketing && this.state.lstMarketing.map((item, index)=> {
                                                return(
                                                    <Checkbox key={index} onChange={(e) => this.addMarkerting(e)} value={item.id} checked={this.state.marketing.includes(item.id.toString())}>{item.ten}</Checkbox>
                                                )})
                                            }
                                            </Form.Item>
                                        </div>
                                        <NguonKhachHang ref={(c) => this.nguonKhachHang = c} managermentId={this.state.detail.managementID} updateCoporator={(info) => { this.checkBangGia(info) }}/>
                                    </div>
                                    <div className="" style={{ display: 'none', marginTop: 5 }}>
                                        <Form.Item label="Mã BN:" style={{ flex: 1 }}>
                                            <Input disabled
                                                value={this.state.mabn} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                        <button className="btn btn-color"
                                            style={{ width: 85 }}
                                            onClick={() => this.updateBN()}><i className="fa fa-save" style={{ marginRight: 5 }}></i>Lưu
                                        </button>
                                        <button className="btn btn-danger" style={{ marginLeft: 10 }}
                                            onClick={() => {
                                                this.setState({
                                                    visibleEdit: false,
                                                    marketing : [],
                                                })
                                            }}>
                                            <i className="fas fa-sign-out-alt" style={{ marginRight: 5 }}
                                            /> Đóng
                                        </button>
                                    </div>
                                </div>

                                <div className="border-register">
                                    <div data-toggle="collapse" data-target="#relative" className="h4 font-weight-bold" id="btn-relative">
                                        Thông tin người thân
                                    </div>
                                    <div id="relative" className="collapse" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                                        <table className="table table-striped table-hover table-bordered w-100">
                                            <thead>
                                                <th>Quan hệ</th>
                                                <th>Họ tên</th>
                                                <th>Điện thoại</th>
                                                <th>Địa chỉ</th>
                                            </thead>
                                            <tbody>
                                                {this.state.lstRelative.map((row, index) => (
                                                    <tr key={'1' + index}>
                                                        <td>{row.relative}</td>
                                                        <td>{row.relativeName}</td>
                                                        <td>{row.relativePhone}</td>
                                                        <td>{row.relativeAddress}</td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="border-register">
                                    <div data-toggle="collapse" data-target="#history" className="h4 font-weight-bold" id="btn-history">
                                        Lịch sử khám bệnh
                                    </div>
                                    <div id="history" className="collapse" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                                        <table className="table table-striped table-hover table-bordered w-100">
                                            <thead>
                                                <th>Ngày</th>
                                                <th>Loại khám</th>
                                                <th>Lý do</th>
                                                <th>Bác sĩ</th>
                                            </thead>
                                            {this.state.patientHistoryInfo.map((row, index) => (
                                                <tbody key={'1-' + index}>
                                                    <tr key={'1-' + index}>
                                                        <td>{moment(row.time).format('DD-MM-YYYY')}</td>
                                                        <td>{row.ten}</td>
                                                        <td>{row.lydo}</td>
                                                        <td>{row.tenbs}</td>
                                                    </tr>
                                                    {row.subtrans.length > 0 && row.subtrans.map((child, indexChild) => (
                                                        indexChild > 0 &&
                                                        <tr key={'2-' + indexChild}>
                                                            <td colSpan="4">{child.title}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            ))
                                            }
                                        </table>
                                    </div>
                                </div>
                                <div className="border-register">
                                    <div data-toggle="collapse" data-target="#fileupload" className="h4 font-weight-bold" id="btn-fileupload">
                                        Hồ sơ
                                    </div>
                                    <div id="fileupload" className="collapse" style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                                        {/* <Upload
                                            patientid={this.state.detail.patientID}
                                            isUpload={true}
                                        /> */}
                                        <UploadNew 
                                            featureName="patient" 
                                            componentKey="patientInfo" 
                                            layout="MediumIcons"
                                            extention=".png, .jpg, .jpge, .docx, .doc, .pdf"
                                            isEdit={true}  
                                            refId={this.state.detail.patientID}
                                            />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tab={"DỊCH VỤ ĐĂNG KÝ"} key="2">
                                <div style={{ padding: '0px 20px 20px 20px', height: '100%' }}>
                                    <ModalCLS maskClosable={false}
                                        chinhanh={this.props.chinhanh}
                                        ref={((c) => this.modalCLS = c)}
                                        patientID={this.state.detail.patientID}
                                        managementID={this.state.detail.managementID}
                                        dateIn={this.state.detail.dateIn}
                                        deleteFunc={(row) => { this.deleteRow(row) }}
                                        editAfterRegister={true}
                                        onClose={(data) => { this.setState({ visibleEdit: false }); this.AddRow(data) }}
                                        updateDataTable={this.updateDataTable.bind(this)}
                                        showGoiKham={false}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </Modal>
                }
                </Form>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return ({
        siteid: state.global.siteid,
        siteCode: state.global.siteCode,
        marketingRequired: state.global.sysConfig.marketingRequired ? state.global.sysConfig.marketingRequired : false,
        administrativeRequired: state.global.sysConfig.administrativeRequired ? state.global.sysConfig.administrativeRequired : false,
    })
};

export default connect(mapStateToProps)(patients)